/* Toast container styling */
.custom-toast {
    border-radius: 12px;
    background-color: #333; /* Dark background for the toast */
    color: #fff; /* White text for better contrast */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
  }
  
  /* Toast body styling */
  .custom-toast-body {
    padding: 0;
    margin: 0;
    font-family: 'Arial', sans-serif; /* You can choose any font */
    font-size: 0.9rem;
    color: #fff;
  }
  
  /* Success toast background color */
  .Toastify__toast--success {
    background: #28a745 !important; /* Green success background */
    border-left: 8px solid #218838; /* Darker green border */
  }
  
  /* Error toast background color */
  .Toastify__toast--error {
    background: #dc3545 !important; /* Red error background */
    border-left: 8px solid #c82333; /* Darker red border */
  }
  
  /* Info toast background color */
  .Toastify__toast--info {
    background: #17a2b8 !important; /* Blue info background */
    border-left: 8px solid #138496; /* Darker blue border */
  }
  
  /* Warning toast background color */
  .Toastify__toast--warning {
    background: #ffc107 !important; /* Yellow warning background */
    border-left: 8px solid #e0a800; /* Darker yellow border */
  }
  
  /* Customize the progress bar */
  .Toastify__progress-bar {
    background: linear-gradient(to right, #fff, #ccc); /* Light gradient for the progress bar */
    height: 5px;
    border-radius: 0 0 12px 12px;
  }
  