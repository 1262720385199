/* General Styles */
.quill-editor .ql-editor {
  border-radius: 15px; /* Border radius for the editor's content area */
  background-color: aquamarine !important;
}

.quill {
  position: relative;
  align-content: center;
  align-self: center !important;
  height: 60vh;
}



.ql-toolbar{
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  align-self: center;
  position: absolute;
  z-index: 100;
  bottom: 0;
  width: 100%;
  /* border-bottom: none !important; */
  /* border-right: none !important;
  border-left: none !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 15px;
  flex-wrap: wrap;
}


.ql-container.ql-snow {
  border-radius: 26px;
  border-top: 1px solid #b9b9b9 !important;
}

/* Responsive Styles */

/* For extra-small devices (280px to 599px, phones) */
@media (max-width: 599px) and (min-width: 280px) {
  .ql-toolbar .ql-snow {
    max-width: 96.3%;
    min-width: 90%;
    padding: 8px;
    bottom: 0; 
  }

  .ql-container.ql-snow {
    border-radius: 8px;
  }

  .quill-editor .ql-editor {
    border-radius: 8px;
  }
}

/* For small devices (600px to 959px, tablets and small desktops) */
@media (min-width: 600px) and (max-width: 959px) {
  .ql-toolbar.ql-snow {
    width: 97%;
    padding: 10px;
    bottom: 0; 
  }

  .ql-container.ql-snow {
    border-radius: 12px;
  }

  .quill-editor .ql-editor {
    border-radius: 12px;
  }
}

/* For medium devices (960px to 1279px, tablets and desktops) */
@media (min-width: 960px) and (max-width: 1279px) {
  .ql-toolbar.ql-snow {
    width: 98%;
    padding: 12px;
    bottom: 0;
  }

  .ql-container.ql-snow {
    border-radius: 18px;
  }

  .quill-editor .ql-editor {
    border-radius: 15px;
  }
}

/* For large devices (1280px to 1919px, desktops) */
@media (min-width: 1280px) and (max-width: 1919px) {
  .ql-toolbar.ql-snow {
    width: 98%;
    padding: 14px;
    bottom: 0; 
  }

  .ql-container.ql-snow {
    border-radius: 22px;
  }

  .quill-editor .ql-editor {
    border-radius: 18px;
  }
}

/* For extra-large devices (1920px and up, wide screens and 4K monitors) */
@media (min-width: 1920px) {
  .ql-toolbar.ql-snow {
    width: 98.8%;
    padding: 15px;
    bottom: 0; 
  }

  .ql-container.ql-snow {
    border-radius: 26px;
  }

  .quill-editor .ql-editor {
    border-radius: 22px;
  }
}

